import React, { useState, useContext, useEffect } from 'react'

import { ColsContext } from 'src/hooks/cols/context'

import Link from 'src/components/link/index'

import styles from './pager.module.scss'


const NewsLink = ({ filter, current, index, children }) => {
    const params = []

    if (filter) {
        params.push(`filter=${filter}`)
    }
    if (index && index > 0) {
        params.push(`page=${index + 1}`)
    }

    const to = `/news/` + (params.length > 0 ? `?${params.join('&')}` : ``)

    if (index === current) {
        return <Link to={to} className={styles.current}>{children}</Link>
    } else {
        return <Link to={to}>{children}</Link>
    }
}

const Pager = ({ pages, current, filter }) => {


    const [links, setLinks] = useState([])

    const { cols } = useContext(ColsContext)


    useEffect(() => {
        let links = []

        if (cols !== 'line_sp' || pages < 6) {
            links = new Array(pages).fill('').map((_, i) => i)
        } else {
            if (current < 2) {
                links.push(0)
                links.push(1)
                links.push(2)
                links.push(null)
                links.push(pages - 1)
            } else if (current >= pages - 2) {
                links.push(0)
                links.push(null)
                links.push(pages - 3)
                links.push(pages - 2)
                links.push(pages - 1)
            } else {
                links.push(current - 1)
                links.push(current)
                links.push(current + 1)
                links.push(null)
                links.push(pages - 1)
            }
        }

        setLinks(links)

    }, [cols, pages, current])


    if (pages > 1) {
        return (
            <div className={styles.pager}>
                <div className={styles.prev}>
                    {current > 0 && (
                        <NewsLink index={current - 1} current={current} filter={filter}>Previous</NewsLink>
                    )}
                </div>
                <ul>
                    {links.map((index, i) => {
                        if (index !== null) {
                            return <li key={i}><NewsLink index={index} current={current} filter={filter}>{index + 1}</NewsLink></li>
                        } else {
                            return <li key={i}>...</li>
                        }
                    })}
                </ul>
                <div className={styles.next}>
                    {current < pages - 1 && (
                        <NewsLink index={current + 1} current={current} filter={filter}>Next</NewsLink>
                    )}
                </div>
            </div>
        )
    } else {
        return null
    }
}

export default Pager