import React from 'react'

import { useLocation } from '@reach/router'
import Link from 'src/components/link/index'

import styles from './index.module.scss'


const Back = ({ to }) => {

    const { state } = useLocation()

    if (false && state?.back) {
        return <button className={styles.button} onClick={() => window.history.go(-state.back)}>一覧に戻る</button >
    } else {
        return <Link className={styles.button} to={to}>一覧に戻る</Link>
    }
}

export default Back