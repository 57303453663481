import React, { useState, useEffect } from 'react'

import SEO from 'src/components/seo/index'
import Date from 'src/components/date/index'
import Back from 'src/components/back/index'
import Header from 'src/components/header/setting'
import Latest from './latest'

import { get } from 'src/lib/api'

import styles from './article.module.scss'


const Article = ({ slug }) => {

    const [ detail, setDetail ] = useState(null)

    useEffect(() => {
        if (typeof window !== `undefined`) {
            get(`/app/api/news/get`, { slug: slug }).then((data) => {
                setDetail(data.detail)
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <SEO title={detail ? detail.title : `NEWS`} />
            <Header hasBack={true} />

            {detail && (
                <div className={styles.detail}>
                    <div className={styles.inner}>
                        <div className={styles.info}>
                            <ul className={styles.tags}>
                                {detail.tags.map((tag, i) => (
                                    <li key={i}>{tag.name}</li>
                                ))}
                            </ul>
                            <Date date={detail.date} className={styles.date} />
                        </div>
                        <div className={styles.title}>
                            <h1>{detail.title}</h1>
                        </div>
                    </div>

                    <div className={styles.mainimage}>
                        <img src={detail.mainimage} alt="" />
                    </div>

                    <div className={styles.content}>
                        <div className={styles.inner} dangerouslySetInnerHTML={{ __html: detail.content }}></div>
                    </div>

                    <div className={styles.back}>
                        <Back to="/news/" />
                    </div>
                </div>
            )}

            {detail?.slug && (
                <Latest slug={detail.slug} />
            )}
        </div>
    )
}

export default Article