import React, { useState, useEffect } from 'react'

import SEO from 'src/components/seo/index'
import Header from 'src/components/header/setting'
import Title from 'src/components/title/index'
import Inner from 'src/components/inner/index'
import Link from 'src/components/link/index'
import Item from './item'
import Pager from './pager'

import { get } from 'src/lib/api'

import styles from './list.module.scss'


const List = ({ location }) => {
    const [ tags, setTags ] = useState([])
    const [ nodes, setNodes ] = useState([])

    const [ pages, setPages ] = useState(1)
    const [ index, setIndex ] = useState(0)
    const [ filter, setFilter] = useState(null)

    useEffect(() => {
        if (typeof window !== `undefined`) {
            const params = {}

            if (location.search && location.search.match(/^\?(.+)/)) {
                const query = RegExp.$1.split('&').reduce((query, pair) => {
                    if (pair.match(/^(.+)=(.+)$/)) query[RegExp.$1] = RegExp.$2
                    return query
                }, {})

                if (`page` in query && query.page.match(/[0-9]+/)) {
                    params['index'] = query.page - 1
                    setIndex(params['index'])
                }
                if (`filter` in query) {
                    params['tags'] = query.filter
                    setFilter(params['tags'])
                }
            }

            get(`/app/api/news/list`, params).then((data) => {
                setTags(data.tags)
                setNodes(data.nodes)
                setPages(data.pages)
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div>
            <SEO title="News" />
            <Header hasBack={true} />
            
            <div className={styles.newsList}>
                <Title h1="NEWS" right={(
                    <ul className={styles.newsFilter}>
                        <li><Link to="/news/" className={filter ? `` : styles.current}>All Caterogies</Link></li>

                        {tags.map((tag) => (
                            <li key={tag.slug}>
                                <Link to={`/news/?filter=${tag.slug}`} className={filter === tag.slug ? styles.current : ``}>{tag.name}</Link>
                            </li>
                        ))}
                    </ul>
                )} />

                <Inner className={styles.newsBody}>
                    <ul>
                        {nodes.map((item, i) => <li key={i}><Item {...item} /></li>)}
                    </ul>
                    <Pager current={index} pages={pages} filter={filter} />
                </Inner>
            </div>
        </div>
    )
}

export default List