import React from 'react'

const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']


const DateComponent = ({ date, className }) => {

    const _d = new Date(date)

    return (
        <span className={className}>{_d.getDate()} {MONTH_NAMES[_d.getMonth()]} {_d.getFullYear()}</span>
    )
}

export default DateComponent